<template>
  <div>
    <div id="contasPagar" class="cardTable mb-1 vs-con-loading__container">
      <div class="mt-1" v-if="contas.length == 0 || contas.length == undefined">
        <div class="pt-20 scroll-contas-pagar con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhuma Conta a Pagar
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="scroll-contas-pagar">
        <!-- <VuePerfectScrollbar class="scroll-contas-pagar" :settings="settings">
          <vs-table stripe max-items="9" pagination :data="contas">
            <template slot="thead">
              <vs-th sort-key="nota" style="width: 5%" id="center">Nota</vs-th>
              <vs-th sort-key="emissao" style="width: 8%" id="center">Emissão</vs-th>
              <vs-th sort-key="vencimento" style="width: 8%" id="center">Vencimento</vs-th>
              <vs-th sort-key="quitacao" style="width: 8%" id="center">Quitação</vs-th>
              <vs-th style="width: 8%">Fornecedor</vs-th>
              <vs-th style="width: 8%">Empresa</vs-th>
              <vs-th style="width: 8%" id="center">Valor</vs-th>
              <vs-th style="width: 8%" id="center">Juros</vs-th>
              <vs-th style="width: 8%" id="center">V. Pago</vs-th>
              <vs-th style="width: 10%" id="center">Plano Contas</vs-th>
              <vs-th style="width: 10%" id="center">Ações</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                style="cursor: default !important"
                :data="conta"
                :style="
                  conta.id_status == 4
                    ? 'color: green;background-color:#e8fce8 !important;'
                    : conta.id_status == 3
                    ? 'color:#00BBA2;background-color: #dff2f0 !important'
                    : conta.vencido > 0
                    ? 'color:red ;background-color: #fce3e3 !important'
                    : ''
                "
                :key="index"
                v-for="(conta, index) in data"
              >
                <vs-td :data="conta.nota" class="text-center">
                  <b>{{conta.nota > 0 ? conta.nota + "." + conta.parcela : '...'}}</b>
                </vs-td>
                <vs-td :data="conta.emissao" class="text-center" style="font-size: 12px">
                  <b>{{ conta.emissao }}</b>
                </vs-td>
                <vs-td :data="conta.vencimento" class="text-center" style="font-size: 12px">
                  <b>{{ conta.vencimento ? conta.vencimento : "..." }}</b>
                </vs-td>
                <vs-td :data="conta.pagamento" class="text-center" style="font-size: 12px">
                  <b>{{ conta.pagamento ? conta.pagamento : "..." }}</b>
                </vs-td>
                <vs-td
                  style="color: black !important; font-size: 10px"
                  class="font-semibold"
                  :data="conta.id_fornecedor"
                >{{ conta.fornecedor }}</vs-td>
                <vs-td
                  style="color: black !important; font-size: 10px"
                  class="font-semibold"
                >{{ conta.empresa }}</vs-td>
                <vs-td class="text-center" :data="conta.valor" style="font-size: 12px">
                  <b>{{ $currency(conta.valor) }}</b>
                </vs-td>
                <vs-td :data="conta.juros" class="text-center" style="font-size: 12px">
                  <b>{{ $currency(conta.juros) }}</b>
                </vs-td>
                <vs-td class="text-center" :data="conta.valor_pago" style="font-size: 12px">
                  <b>{{ $currency(conta.valor_pago) }}</b>
                </vs-td>
                <vs-td class="text-center" style="font-size: 12px">
                  <b>{{ conta.plano_conta }}</b>
                </vs-td>
                <vs-td class="actions">
                  <vs-col vs-w="4">
                    <Editar @update="getContasPagar" :conta="conta" :id_status="conta.id_status" />
                  </vs-col>
                  <vs-col vs-w="4">
                    <Quitar @update="getContasPagar" :conta="conta" />
                  </vs-col>
                  <vs-col vs-w="4">
                    <el-popconfirm
                      :confirmButtonText="
                        conta.id_status > 2 ? 'Desquitar' : 'Excluir conta'
                      "
                      cancelButtonText="Cancelar"
                      :icon="
                        conta.id_status > 2
                          ? 'el-icon-circle-close'
                          : 'el-icon-delete'
                      "
                      @confirm="
                        conta.id_status > 2
                          ? quitacaoDelet(conta)
                          : deletar(conta)
                      "
                      iconColor="red"
                      :title="
                        conta.id_status > 2
                          ? conta.id_status == 3
                            ? 'Cancelar quitação parcial desta conta?'
                            : 'Cancelar quitação desta conta?'
                          : 'Deseja excluir esta conta?'
                      "
                    >
                      <vs-button
                        :disabled="
                          !$atividades.permissoes.includes(6) ||
                          buttonDisabled ||
                          conta.id_status > 4
                        "
                        slot="reference"
                        icon="close"
                        size="small"
                        color="danger"
                      />
                    </el-popconfirm>
                  </vs-col>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </VuePerfectScrollbar> -->
        <b-table-simple sticky-header="80vh" striped responsive>
          <b-thead>
            <b-tr>
              <b-th style="width: 5%" class="text-center">Nota</b-th>
              <b-th style="width: 5%" class="text-center">Emissão</b-th>
              <b-th style="width: 5%" class="text-center">Vencimento</b-th>
              <b-th style="width: 5%" class="text-center">Quitação</b-th>
              <b-th style="width: 10%" class="text-center">Fornecedor</b-th>
              <b-th style="width: 10%" class="text-center">Empresa</b-th>
              <b-th style="width: 8%" class="text-center">Valor</b-th>
              <b-th style="width: 8%" class="text-center">Juros</b-th>
              <b-th style="width: 8%" class="text-center">V. Pago</b-th>
              <b-th style="width: 12%" class="text-center">Plano Conta</b-th>
              <b-th style="width: 10%" class="text-center">Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              :style="
                conta.id_status == 4
                  ? 'color: green;background-color:#e8fce8 !important;'
                  : conta.id_status == 3
                  ? 'color:#00BBA2;background-color: #dff2f0 !important'
                  : conta.vencido > 0
                  ? 'color:red ;background-color: #fce3e3 !important'
                  : ''
              "
              :key="index"
              v-for="(conta, index) in contas"
            >
              <b-td style="font-size: 12px" class="text-center font-bold">{{
                conta.nota > 0
                  ? conta.nota + (conta.parcela ? "." + conta.parcela : "")
                  : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.emissao ? conta.emissao : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.vencimento ? conta.vencimento : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.pagamento ? conta.pagamento : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.fornecedor ? conta.fornecedor : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.empresa ? conta.empresa : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center font-bold">{{
                $currency(conta.valor)
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center font-bold">{{
                $currency(conta.juros)
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center font-bold">
                {{
                  $currency(
                    parseFloat(conta.valor_pago) + parseFloat(conta.juros || 0)
                  )
                }}
              </b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.plano_conta ? conta.plano_conta : "..."
              }}</b-td>
              <b-td style="font-size: 12px">
                <div class="w-full">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-button
                        icon="edit"
                        @click="renderComponent('Editar', conta)"
                        size="small"
                        :disabled="
                          !$atividades.permissoes.includes(5) ||
                          conta.id_status == 4 ||
                          conta.id_status == 5
                        "
                        color="primary"
                      />
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-button
                        icon="done"
                        :disabled="
                          !$atividades.permissoes.includes(5) ||
                          conta.id_status == 4 ||
                          conta.id_status == 5
                        "
                        @click="renderComponent('Quitar', conta)"
                        size="small"
                        :color="conta.id_status == 4 ? '#128712' : 'success'"
                      />
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <el-popconfirm
                        :confirmButtonText="
                          conta.id_status > 2 ? 'Desquitar' : 'Excluir conta'
                        "
                        cancelButtonText="Cancelar"
                        :icon="
                          conta.id_status > 2
                            ? 'el-icon-circle-close'
                            : 'el-icon-delete'
                        "
                        @confirm="
                          conta.id_status > 2
                            ? quitacaoDelet(conta)
                            : deletar(conta)
                        "
                        iconColor="red"
                        :title="
                          conta.id_status > 2
                            ? conta.id_status == 3
                              ? 'Cancelar quitação parcial desta conta?'
                              : 'Cancelar quitação desta conta?'
                            : 'Deseja excluir esta conta?'
                        "
                      >
                        <vs-button
                          :disabled="
                            !$atividades.permissoes.includes(6) ||
                            buttonDisabled ||
                            conta.id_status > 4
                          "
                          slot="reference"
                          icon="close"
                          size="small"
                          color="danger"
                        />
                      </el-popconfirm>
                    </vs-col>
                  </vs-row>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
    <vx-card no-shadow card-background="dark">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1"
          class
        >
          <vs-button
            @click="voltar()"
            icon-pack="feather"
            line-origin="left"
            color="#fff"
            type="line"
            icon="icon-arrow-left"
            size="small"
          >
            <span style="font-size: 14px">Voltar</span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-w="5"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <span style="color: white; font-size: 14px" class="ml-10 pt-3"
            >Contas: {{ contas.length }}</span
          >
          <span style="color: white; font-size: 14px" class="ml-10 pt-3"
            >Valor Total: {{ $currency(total) }}</span
          >
          <span style="color: white; font-size: 14px" class="ml-10 pt-3"
            >Valor Pago: {{ $currency(totalPago) }}</span
          >
        </vs-col>
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
        >
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <Cadastrar @update="getContasPagar" />
          </vs-col>
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <Filtro @filtro="Filtrar" />
          </vs-col>
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <vs-button
              icon="get_app"
              type="line"
              @click="exportToExcel()"
              color="#FFCA00"
              size="small"
            >
              <b style="font-size: 16px">Excel</b>
            </vs-button>
          </vs-col>
        </vs-col>
      </vs-row>
    </vx-card>
    <component
      v-bind:is="buttonComponent"
      :conta="selectedItem"
      :key="reRenderButtonComponent"
      @update="getContasPagar"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import Filtro from "./components/filtro"
import Cadastrar from "./components/cadastrar"
import Quitar from "./components/quitar"
import Editar from "./components/editar"
export default {
  data () {
    return {
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.5,
        height: 1000,
      },
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
      },
      contas: {},
      totalPago: 0,
      total: 0,
      buttonDisabled: false,
      selectedItem: {},
      buttonComponent: "",
      reRenderButtonComponent: 0,
    }
  },
  methods: {
    async getContasPagar () {
      await this.$vs.loading({ container: "#contasPagar", scale: 0.6 })
      try {
        this.contas = await this.$http.post(`getContasPagar`, this.filtro)
        this.total = this.contas.reduce(function (total, conta) {
          return total + conta.valor
        }, 0)
        this.totalPago = this.contas.reduce(function (total, conta) {
          return total + conta.valor_pago
        }, 0)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      await this.$vs.loading.close("#contasPagar > .con-vs-loading")
    },
    async exportToExcel () {
      try {
        import("@/vendor/Export2Excel").then(async (excel) => {
          let headerTitle = [
            "Nota",
            "Data Emissão",
            "Data Vencimento",
            "Data Pagamento",
            "Fornecedor",
            "Valor",
            "Juros",
            "Valor Pago",
          ]
          let headerVal = [
            "nota",
            "emissao",
            "ncimento",
            "pagamento",
            "fornecedor",
            "valor",
            "juros",
            "valor_pago",
          ]
          const list = this.contas
          const data = await this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: "Contas a Pagar",
            autoWidth: true,
            bookType: "xlsx",
          })
        })
        this.$vs.loading.close()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j]
        })
      )
    },
    async voltar () {
      this.$router.push("/financeiro")
    },
    async Filtrar (filtro) {
      this.filtro = await filtro
      await this.getContasPagar()
    },
    async deletar (conta) {
      this.buttonDisabled = await true
      try {
        await this.$http.put(`conta/` + conta.id, { id_status: 5 })
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão da Conta a Pagar N°" + conta.id,
        }
        await this.$logger(logData)
        await this.getContasPagar()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.buttonDisabled = await false
      }
    },
    async quitacaoDelet (conta) {
      if(this.$atividades.permissoes.includes(17)){
        this.buttonDisabled = await true
        try {
          await this.$http.put(`conta/` + conta.id, {
            id_status: 1,
            data_pagamento: null,
          })
          await this.$http.delete(`desquitar/` + conta.id)
          let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: "excluir",
            setor: "financeiro",
            ip: window.localStorage.getItem("ip"),
            texto: "Exclusão da Quitação da Conta a Pagar N°" + conta.id,
          }
          await this.$logger(logData)
          await this.getContasPagar()
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        } finally {
          this.buttonDisabled = await false
        }
      }else{
        this.$vs.notify({title: "Não autorizado", text: "Você não tem autorização para realizar esta ação", color: "danger"})
      }
    },
    async renderComponent (component2Render, item) {
      this.buttonComponent = component2Render
      this.selectedItem = item
      this.reRenderButtonComponent++
    },
    async contrato (id_contrato) {
      this.$router.push("/contrato?id=" + id_contrato)
    },
  },
  async mounted () {
    await this.getContasPagar()
  },
  components: {
    Filtro,
    ...components,
    Cadastrar,
    Editar,
    Quitar,
  },
};
</script>
<style lang="scss">
.scroll-contas-pagar {
  height: 82vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.el-popconfirm__main {
  font-size: 12px !important;
}
.table_height {
  height: 72vh !important;
}
</style>
