<template>
  <div class="w-full">
    <vx-card class="cadastro-parcelas overflow-hidden" @click="openModal()">
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
              <span>
                <vs-icon class="img" pack-icon icon="dynamic_feed" size="35px" color="#0f4084"></vs-icon>
              </span>
            </vs-col>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="pb-5">
              <span style="font-size: 20px">Parcelas</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>
    <vs-popup title="Nova Conta a Pagar" :active.sync="modalCadastrar">
      <div class="vs-con-loading__container" id="loadingModalParcelas">
        <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
            <div>
              <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Empresa</label>
              <br />
              <el-select
                filterable
                clearable
                name="empresa"
                style="width: 179px"
                v-model="conta.id_empresa"
                :popper-append-to-body="false"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="empresa in empresas"
                  :value="empresa.id"
                  :label="empresa.razao_social"
                  :key="empresa.id"
                ></el-option>
              </el-select>
            </div>
            <div>
              <vs-input
                color="dark"
                class="mx-1"
                style="width: 179px"
                type="text"
                label="Número da Nota"
                v-model="conta.nota"
              ></vs-input>
            </div>
            <div>
              <vs-input
                color="dark"
                class="mx-1"
                v-currency="$currencyFormatterOptions"
                style="width: 179px"
                type="text"
                name="valor"
                label="Valor"
                v-model="conta.valor"
              ></vs-input>
            </div>
          </vs-col>
        </vs-row>
        <div class="w-full vs-con-loading__container" id="loadingSelects">
          <vs-row vs-type="flex" class="mb-2 mt-2" vs-justify="center" vs-align="center" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Fornecedor</label>
                <br />
                <el-select
                  filterable
                  name="fornecedor"
                  clearable
                  remote
                  :remote-method="filtroFornecedor"
                  loading-text="Buscando..."
                  :loading="loading"
                  placeholder="Pesquise"
                  v-model="conta.id_fornecedor"
                  class="mx-1 mt-2"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="(fornecedor, index) in fornecedores"
                    :value="fornecedor.id"
                    :label="fornecedor.nome_fantasia"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Portador</label>
                <br />
                <el-select
                  filterable
                  clearable
                  name="portador"
                  :popper-append-to-body="false"
                  v-model="conta.id_portador"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="portador in portadores"
                    :value="portador.id"
                    :label="portador.nome"
                    :key="portador.id"
                  ></el-option>
                </el-select>
              </div>

              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Plano Conta</label>
                <br />
                <el-select
                  filterable
                  clearable
                  name="plano_conta"
                  remote
                  :loading="loading"
                  :remote-method="filtroPlanoConta"
                  :popper-append-to-body="false"
                  placeholder="Pesquise"
                  v-model="conta.id_plano_conta"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in plano_conta"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
          <vs-row vs-type="flex" class="mb-2 mt-2" vs-justify="center" vs-align="center" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Forma de Pagamento</label>
                <br />
                <el-select
                  filterable
                  name="formas_pagamento"
                  clearable
                  style="width: 179px"
                  :popper-append-to-body="false"
                  v-model="conta.id_forma_pagamento"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="forma in formas_pagamento"
                    :value="forma.id"
                    :label="forma.nome"
                    :key="forma.id"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <vs-input
                  color="dark"
                  class="mx-2"
                  type="date"
                  style="width: 179px"
                  name="emissao"
                  label="Data Emissão"
                  v-model="conta.data_emissao"
                ></vs-input>
              </div>
              <div>
                <vs-input
                  v-model="quantidade"
                  type="number"
                  min="1"
                  max="24"
                  style="width: 179px"
                  @change="gerarDatas()"
                  class="mx-2"
                  name="datas"
                  label="Quantidade de Parcelas"
                />
              </div>
            </vs-col>
          </vs-row>
        </div>
        <vs-row vs-type="flex" class="mb-2 mt-2" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12"></vs-col>
        </vs-row>

        <vs-row class="mb-4" vs-align="center" vs-justify="flex-start" vs-type="flex" vs-w="12">
          <div id="scrollLoadingDatas" class="vs-con-loading__container">
            <VuePerfectScrollbar id="scroll" class="scroll-contas-parcelas" :settings="settings">
              <vs-col
                vs-align="center"
                vs-justify="flex-start"
                vs-type="flex"
                vs-w="6"
                v-for="data in datas"
                :key="data.parcela"
              >
                <div class="w-full">
                  <vs-input
                    :label="'Parcela N° ' + data.parcela"
                    type="date"
                    style="width: 270px"
                    class="mx-2"
                    v-validate="'required'"
                    color="dark"
                    :name="'data' + data.parcela"
                    v-model="data.data"
                  />
                </div>
              </vs-col>
            </VuePerfectScrollbar>
          </div>
        </vs-row>
        <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
            <vs-input
              color="dark"
              class="mx-1 w-fulll"
              style="width: 600px"
              type="text"
              label="Observação"
              v-model="conta.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
            <vs-button
              @click="submitForm()"
              class="w-full mt-3"
              color="success"
              type="relief"
            >Incluir</vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {},
  data() {
    return {
      modalCadastrar: false,
      quantidade: 1,
      datas: [],
      loading: false,
      portadores: {},
      empresas: {},
      fornecedores: {},
      plano_conta: {},
      formas_pagamento: {},
      conta: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
        height: 1000
      }
    };
  },
  methods: {
    async openModal() {
      this.modalCadastrar = await true;
      await this.$vs.loading({ container: "#loadingSelects", scale: 0.6 });
      try {
        await this.gerarDatas();
        await this.getPortadores();
        await this.getEmpresas();
        await this.getFormasPagamento();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#loadingSelects > .con-vs-loading");
      }
    },
    async submitForm() {
      try {
        let valid = true;
        for (const data of Object.values(this.datas)) {
          if (data.data == null || data.data == 0) {
            valid = false;
          }
        }
        if (
          this.conta.id_empresa != null &&
          this.conta.nota != null &&
          this.conta.valor != null &&
          this.conta.id_fornecedor != null &&
          this.conta.id_portador != null &&
          this.conta.id_plano_conta != null &&
          this.conta.id_forma_pagamento != null &&
          this.conta.data_emissao != null &&
          valid
        ) {
          await this.Cadastrar();
        } else {
          this.$vs.notify({
            title: "Preencha os campos obrigatorios",
            text:
              "Empresa <br> Nota <br> Valor <br> Fornecedor <br> Portador <br> Plano Conta <br> Forma de Pagamento <br> Data de Emissão <br> Datas de Vencimento",
            color: "danger"
          });
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async Cadastrar() {
      await this.$vs.loading({
        container: "#loadingModalParcelas",
        scale: 0.6
      });
      try {
        let valor = "" + this.conta.valor;
        if (valor && valor.search(",") !== -1) {
          valor = valor.replace(/\./g, "");
          valor = valor.replace(/,/g, ".");
        }
        this.conta.valor = valor;

        this.conta.id_status = await 1;
        this.conta.tipo = await 1;
        const res = await this.$http.post("salvarParcelasConta", [
          this.conta,
          this.datas
        ]);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro da Conta a Pagar N°" + res.id
        };
        await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.modalCadastrar = await false;
        this.open = await false;
        this.conta = await {};
        await this.$vs.loading.close("#loadingModalParcelas > .con-vs-loading");
      }
    },

    async getPortadores() {
      try {
        this.portadores = await this.$http.get(`portador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get(`empresa`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroFornecedor(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getFornecedorByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getFornecedorByQuery(query) {
      try {
        this.fornecedores = await this.$http.post(`getFornecedorByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPlanoContaByQuery(query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getFormasPagamento() {
      try {
        this.formas_pagamento = await this.$http.get(`formasPagamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroPlanoConta(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getPlanoContaByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async gerarDatas() {
      await this.$vs.loading({ container: "#scrollLoadingDatas", scale: 0.6 });
      if (this.quantidade > 0) {
        this.datas = [];
        let date = this.$formartData.dataHoje();
        for (let i = 1; i <= this.quantidade; i++) {
          let dataFormatada = await this.$formartData.formatar(date, 1);
          this.datas.push({ data: dataFormatada, parcela: i });
          date = await this.$formartData.somarDias(date, 30);
        }
        this.conta.data_emissao = this.$formartData.dataFiltro();
      } else {
        this.$vs.notify({
          title: "Quantidade Inválida",
          text: "Insira uma quantidade maior que 0",
          color: "danger"
        });
      }
      await this.$vs.loading.close("#scrollLoadingDatas > .con-vs-loading");
    }
  },
  async mounted() {},
  components: { ...components }
};
</script>
<style lang="scss">
.cadastro-parcelas {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #0f4084;
    color: white;
    cursor: pointer;
    .img {
      color: white !important;
    }
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-contas-parcelas {
  max-height: 26vh !important;
}
</style>
