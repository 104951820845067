<template>
  <div class="w-full">
    <vs-popup v-if="open" title="Nova Conta a Pagar" :active.sync="modalEditar">
      <div class="vs-con-loading__container" :id="'loadingEditCP' + conta.id">
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <div>
              <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                >Empresa</label
              >
              <br />
              <el-select
                filterable
                clearable
                name="empresa"
                style="width: 181px"
                v-model="objConta.id_empresa"
                :popper-append-to-body="false"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="empresa in empresas"
                  :value="empresa.id"
                  :label="empresa.razao_social"
                  :key="empresa.id"
                ></el-option>
              </el-select>
            </div>
            <div>
              <vs-input
                color="dark"
                class="mx-1"
                type="text"
                style="width: 181px"
                placeholder="00000"
                label="Número da Nota"
                v-model="objConta.nota"
              ></vs-input>
            </div>
            <div>
              <vs-input
                color="dark"
                class="mx-1"
                type="text"
                name="valor"
                style="width: 181px"
                label="Valor"
                v-currency="$currencyFormatterOptions"
                v-model="objConta.valor"
              ></vs-input>
            </div>
          </vs-col>
        </vs-row>
        <div
          :id="'loadingSelectsEditCP' + conta.id"
          class="w-full vs-con-loading__container"
        >
          <vs-row
            vs-type="flex"
            class="mb-2 mt-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="12"
            >
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Fornecedor</label
                >
                <br />
                <el-select
                  filterable
                  name="fornecedor"
                  clearable
                  remote
                  :remote-method="filtroFornecedor"
                  :loading="loading"
                  loading-text="Buscando..."
                  placeholder="Pesquise"
                  v-model="objConta.id_fornecedor"
                  class="mx-1 mt-2"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="(fornecedor, index) in fornecedores"
                    :value="fornecedor.id"
                    :label="fornecedor.nome_fantasia"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Portador</label
                >
                <br />
                <el-select
                  filterable
                  clearable
                  name="portador"
                  :popper-append-to-body="false"
                  v-model="objConta.id_portador"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="portador in portadores"
                    :value="portador.id"
                    :label="portador.nome"
                    :key="portador.id"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Plano Conta</label
                >
                <br />
                <el-select
                  filterable
                  clearable
                  remote
                  :loading="loading"
                  :remote-method="filtroPlanoConta"
                  name="plano_conta"
                  placeholder="Pesquise"
                  :popper-append-to-body="false"
                  v-model="objConta.id_plano_conta"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in plano_conta"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            class="mb-2 mt-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="12"
            >
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Forma de Pagamento</label
                >
                <br />
                <el-select
                  filterable
                  name="forma_pagamento"
                  clearable
                  style="width: 179px"
                  :popper-append-to-body="false"
                  v-model="objConta.id_forma_pagamento"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="tipo in formas_pagamento"
                    :value="tipo.id"
                    :label="tipo.nome"
                    :key="tipo.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
        </div>
        <vs-row
          vs-type="flex"
          class="mb-2 mt-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <div>
              <vs-input
                color="dark"
                class="mx-2"
                type="date"
                style="width: 270px"
                name="emissao"
                @change="checkData"
                label="Data Emissão"
                v-model="objConta.data_emissao"
              ></vs-input>
            </div>
            <div>
              <vs-input
                class="mx-2"
                name="vencimento"
                color="dark"
                type="date"
                style="width: 270px"
                label="Data Vencimento"
                v-model="objConta.data_vencimento"
              ></vs-input>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-1 w-fulll"
              style="width: 600px"
              type="text"
              label="Observação"
              v-model="objConta.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-button
              @click="submitForm()"
              class="w-full mt-3"
              color="success"
              type="relief"
              >Editar</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  props: {
    conta: {},
    id_status: null
  },
  data() {
    return {
      modalEditar: false,
      empresas: {},
      portadores: {},
      fornecedores: {},
      plano_conta: {},
      formas_pagamento: {},
      loading: false,
      open: false,
      objConta: {}
    };
  },
  methods: {
    async openModal() {
      this.open = await true;
      this.modalEditar = await true;
      await this.$vs.loading({
        container: "#loadingSelectsEditCP" + this.conta.id,
        scale: 0.6
      });
      try {
        await this.getEmpresas();
        await this.getFornecedorByQuery(this.conta.id_fornecedor);
        await this.getFormasPagamento();
        await this.getPortadores();
        await this.getPlanoContaByQuery(this.conta.id_plano_conta);
        await this.makeObj();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#loadingSelectsEditCP" + this.conta.id + "> .con-vs-loading"
        );
      }
    },
    async submitForm() {
      try {
        if (
          this.objConta.id_empresa != null &&
          this.objConta.nota != null &&
          this.objConta.valor != null &&
          this.objConta.id_fornecedor != null &&
          this.objConta.id_portador != null &&
          this.objConta.id_plano_conta != null &&
          this.objConta.id_forma_pagamento != null &&
          this.objConta.data_emissao != null && this.objConta.data_emissao != "" && this.objConta.data_emissao != undefined &&
          this.objConta.data_vencimento != null && this.objConta.data_vencimento != "" && this.objConta.data_vencimento != undefined
        ) {
          await this.Editar();
        } else {
          this.$vs.notify({
            title: "Preencha os campos obrigatorios",
            text:
              "Empresa <br> Nota <br> Valor <br> Fornecedor <br> Portador <br> Plano Conta <br> Forma de Pagamento <br> Data de Emissão <br> Data de Vencimento",
            color: "danger"
          });
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async Editar() {
      await this.$vs.loading({
        container: "#loadingEditCP" + this.conta.id,
        scale: 0.6
      });
      try {
        let valor = "" + this.objConta.valor;
        if (valor && valor.search(",") !== -1) {
          valor = valor.replace(/\./g, "");
          valor = valor.replace(/,/g, ".");
        }
        this.objConta.valor = valor;

        const res = await this.$http.put(
          "conta/" + this.conta.id,
          this.objConta
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição da Conta a Pagar N°" + res.id
        };
        await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.modalEditar = await false;
        await this.$vs.loading.close("#loadingEditPagar > .con-vs-loading");
        await this.$emit("update");
        this.modalEditar = await false;
        this.open = await false;
        await this.$vs.loading.close(
          "#loadingEditCP" + this.conta.id + "> .con-vs-loading"
        );
      }
    },
    async makeObj() {
      try {
        this.objConta = {
          nota: this.conta.nota,
          valor: this.conta.valor,
          id_fornecedor: this.conta.id_fornecedor,
          id_empresa: this.conta.id_empresa,
          id_portador: this.conta.id_portador,
          id_plano_conta: this.conta.id_plano_conta,
          data_emissao: this.conta.data_emissao,
          data_vencimento: this.conta.data_vencimento,
          id_forma_pagamento: this.conta.id_forma_pagamento,
          obs: this.conta.obs
        };
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPortadores() {
      try {
        this.portadores = await this.$http.get(`portador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get(`empresa`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroFornecedor(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getFornecedorByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getFornecedorByQuery(query) {
      try {
        this.fornecedores = await this.$http.post(`getFornecedorByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPlanoContaByQuery(query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroPlanoConta(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getPlanoContaByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getFormasPagamento() {
      try {
        this.formas_pagamento = await this.$http.get(`formasPagamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async checkData() {
      if (this.$atividades.permissoes.includes(17)) {
        return 1;
      }
      var hoje = new Date();
      var data = new Date(this.objConta.data_vencimento);

      var dia_hoje = hoje.getUTCDate();
      var mes_hoje = hoje.getUTCMonth() + 1;
      var dia_data = data.getUTCDate();
      var mes_data = data.getUTCMonth() + 1;
      var ano_hoje = hoje.getUTCFullYear();
      var ano_data = data.getUTCFullYear();

      console.log("dia_hoje - " + dia_hoje);
      console.log("mes_hoje - " + mes_hoje);
      console.log("dia_data - " + dia_data);
      console.log("mes_data - " + mes_data);
      console.log("ano_hoje - " + ano_hoje);
      console.log("ano_data - " + ano_data);
      if (ano_data < ano_hoje && 0) {
        this.objConta.data_vencimento = hoje.toISOString().split("T")[0];
        this.$vs.notify({
          title:
            "Não é possível mudar o vencimento do ano anterior. ERR COD 03",
          text: "Ação Negada",
          color: "danger"
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "burl",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto:
            "Tentou alterar para uma data anterior da conta pagar N°" +
            this.conta.id
        };
        await this.$logger(logData);
        return 1;
      }
      if (dia_hoje > 5) {
        if (mes_data < mes_hoje) {
          this.objConta.data_vencimento = hoje.toISOString().split("T")[0];
          this.$vs.notify({
            title:
              "Não é possível mudar o vencimento do mês anterior. ERR COD 01",
            text: "Ação Negada",
            color: "danger"
          });
          let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: "burl",
            setor: "financeiro",
            ip: window.localStorage.getItem("ip"),
            texto:
              "Tentou alterar para uma data anterior da conta pagar N°" +
              this.conta.id
          };
          await this.$logger(logData);
          return 1;
        }
      } else {
        if (mes_hoje + 1 < mes_data && !(mes_hoje == 1 && mes_data == 12)) {
          this.objConta.data_vencimento = hoje.toISOString().split("T")[0];
          this.$vs.notify({
            title:
              "Não é possível mudar o vencimento do mês anterior. ERR COD 02",
            text: "Ação Negada",
            color: "danger"
          });
          let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: "burl",
            setor: "financeiro",
            ip: window.localStorage.getItem("ip"),
            texto:
              "Tentou alterar para uma data anterior da conta receber N°" +
              this.conta.id
          };
          await this.$logger(logData);
          return 1;
        }
      }
    }
  },
  async mounted() {
    await this.openModal();
  },
  components: { ...components }
};
</script>
