<template>
  <div>
    <vs-button icon="search" type="line" size="small" @click="openModal()" color="#3da4fd">
      <b style="font-size: 16px">Filtrar</b>
    </vs-button>
    <vs-popup v-if="open" title="Filtrar" :active.sync="modalFiltro">
      <div class="vs-con-loading__container" id="loadingFiltroCP">
        <div class="vs-con-loading__container" id="loadingSelectsCP">
          <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="4">
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Empresa</label>
                <br />
                <el-select
                  filterable
                  clearable
                  placeholder="Empresa"
                  v-model="filtro.empresa"
                  :popper-append-to-body="false"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="empresa in empresas"
                    :value="empresa.id"
                    :label="empresa.razao_social"
                    :key="empresa.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="4">
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Fornecedor</label>
                <br />
                <el-select
                  filterable
                  clearable
                  remote
                  :remote-method="filtroFornecedor"
                  loading-text="Buscando..."
                  placeholder="Pesquise"
                  :loading="loading"
                  v-model="filtro.fornecedor"
                  class="mx-1 mt-2"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="(fornecedor, index) in fornecedores"
                    :value="fornecedor.id"
                    :label="fornecedor.nome_fantasia"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="4">
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Plano Conta</label>
                <br />
                <el-select
                  filterable
                  clearable
                  name="plano_conta"
                  placeholder="Pesquise"
                  loading-text="Buscando..."
                  :loading="loading"
                  remote
                  :remote-method="filtroPlanoConta"
                  :popper-append-to-body="false"
                  v-model="filtro.id_plano_conta"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in plano_conta"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
          <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="4">
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Status da Conta</label>
                <br />
                <el-select
                  filterable
                  clearable
                  name="status"
                  :popper-append-to-body="false"
                  v-model="filtro.id_status"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in status"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="4">
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Tipo Pessoa</label>
                <br />
                <el-select
                  filterable
                  clearable
                  name="status"
                  :popper-append-to-body="false"
                  v-model="filtro.tipo_pessoa"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="tipo in tipo_pessoa"
                    :value="tipo.value"
                    :label="tipo.nome"
                    :key="tipo.value"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="4">
              <vs-input
                color="dark"
                class="mx-1"
                type="text"
                placeholder="00000"
                label="NF"
                v-model="filtro.nf"
              ></vs-input>
            </vs-col>
          </vs-row>
        </div>

        <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-input
              color="dark"
              class="w-full mx-1"
              type="text"
              placeholder="R$ 0,00"
              label="Valor De"
              v-currency="$currencyFormatterOptions"
              v-model="filtro.valorDe"
            ></vs-input>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-input
              color="dark"
              class="w-full mx-1"
              type="text"
              placeholder="R$ 0,00"
              label="Valor Até"
              v-currency="$currencyFormatterOptions"
              v-model="filtro.valorAte"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-input
              color="dark"
              class="w-full mx-1"
              type="date"
              label="Vencimento De"
              v-model="filtro.dataDe"
            ></vs-input>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-input
              class="w-full mx-1"
              color="dark"
              type="date"
              label="Vencimento Até"
              v-model="filtro.dataAte"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-input
              color="dark"
              class="w-full mx-1"
              type="date"
              label="Emissão De"
              v-model="filtro.emissaoDe"
            ></vs-input>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-input
              color="dark"
              class="w-full mx-1"
              type="date"
              label="Emissão Até"
              v-model="filtro.emissaoAte"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-button
              @click="Filtrar()"
              class="w-full mt-3 mx-1"
              color="success"
              type="relief"
            >Filtrar</vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-button
              @click="(limpar = true), Filtrar()"
              class="w-full mt-3 mx-1"
              color="danger"
              type="relief"
            >Filtrar e Limpar</vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  data() {
    return {
      tipo_pessoa: [
        { value: 1, nome: "PESSOA FÍSICA" },
        { value: 2, nome: "PESSOA JURÍDICA" }
      ],
      empresas: {},
      plano_conta: {},
      fornecedores: {},
      status: {},
      modalFiltro: false,
      filtro: {},
      loading: false,
      limpar: false,
      open: false
    };
  },
  methods: {
    async openModal() {
      this.open = await true;
      this.modalFiltro = await true;
      await this.$vs.loading({ container: "#loadingSelectsCP", scale: 0.6 });
      try {
        this.filtro.dataHoje = this.$formartData.dataFiltro();
        await this.getEmpresas();
        await this.getContaStatus();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#loadingSelectsCP > .con-vs-loading");
      }
    },
    async replaceDots(value) {
      if (value && value.search(",") !== -1) {
        value = value.replace(/\./g, "");
        value = value.replace(/,/g, ".");
      }
      return value;
    },
    async Filtrar() {
      this.filtro.valorDe = await this.replaceDots(this.filtro.valorDe);
      this.filtro.valorAte = await this.replaceDots(this.filtro.valorAte);

      await this.$emit("filtro", this.filtro);
      this.modalFiltro = await false;
      if (this.limpar) {
        this.filtro = {};
        this.limpar = false;
      }
    },
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get(`empresa`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContaStatus() {
      try {
        this.status = await this.$http.get(`conta_status`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroFornecedor(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getFornecedorByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getFornecedorByQuery(query) {
      try {
        this.fornecedores = await this.$http.post(`getFornecedorByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPlanoContaByQuery(query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroPlanoConta(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getPlanoContaByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    }
  },
  mounted() {},
  components: {
    ...components
  }
};
</script>
