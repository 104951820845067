<template>
  <div class="w-full">
    <vx-card class="cadastro-conta overflow-hidden" @click="openModal()">
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="p-5"
            >
              <span>
                <vs-icon
                  class="img"
                  icon="payment"
                  size="35px"
                  color="#00BBA2"
                ></vs-icon>
              </span>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="pb-5"
            >
              <span style="font-size: 20px">Conta</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>
    <vs-popup title="Nova Conta a Pagar" :active.sync="modalCadastrar">
      <div class="vs-con-loading__container" id="loadingCadCP">
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <div>
              <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                >Empresa</label
              >
              <br />
              <el-select
                filterable
                clearable
                name="empresa"
                style="width: 181px"
                v-model="conta.id_empresa"
                :popper-append-to-body="false"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="empresa in empresas"
                  :value="empresa.id"
                  :label="empresa.razao_social"
                  :key="empresa.id"
                ></el-option>
              </el-select>
            </div>
            <div>
              <vs-input
                color="dark"
                class="mx-1"
                style="width: 181px"
                type="text"
                label="Número da Nota"
                v-model="conta.nota"
              ></vs-input>
            </div>
            <div>
              <vs-input
                color="dark"
                class="mx-1"
                style="width: 181px"
                type="text"
                v-currency="$currencyFormatterOptions"
                name="valor"
                label="Valor"
                v-model="conta.valor"
              ></vs-input>
            </div>
          </vs-col>
        </vs-row>
        <div class="w-full vs-con-loading__container" id="loadingSelectsCadCP">
          <vs-row
            vs-type="flex"
            class="mb-2 mt-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="12"
            >
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Fornecedor</label
                >
                <br />
                <el-select
                  filterable
                  name="fornecedor"
                  clearable
                  remote
                  :remote-method="filtroFornecedor"
                  loading-text="Buscando..."
                  :loading="loading"
                  placeholder="Pesquise"
                  v-model="conta.id_fornecedor"
                  class="mx-1 mt-2"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="(fornecedor, index) in fornecedores"
                    :value="fornecedor.id"
                    :label="fornecedor.nome_fantasia"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Portador</label
                >
                <br />
                <el-select
                  filterable
                  clearable
                  name="portador"
                  :popper-append-to-body="false"
                  v-model="conta.id_portador"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="portador in portadores"
                    :value="portador.id"
                    :label="portador.nome"
                    :key="portador.id"
                  ></el-option>
                </el-select>
              </div>

              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Plano Conta</label
                >
                <br />
                <el-select
                  filterable
                  clearable
                  name="plano_conta"
                  remote
                  :loading="loading"
                  :remote-method="filtroPlanoConta"
                  :popper-append-to-body="false"
                  placeholder="Pesquise"
                  v-model="conta.id_plano_conta"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in plano_conta"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            class="mb-2 mt-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="12"
            >
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Forma de Pagamento</label
                >
                <br />
                <el-select
                  filterable
                  name="formas_pagamento"
                  clearable
                  style="width: 179px"
                  :popper-append-to-body="false"
                  v-model="conta.id_forma_pagamento"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="forma in formas_pagamento"
                    :value="forma.id"
                    :label="forma.nome"
                    :key="forma.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
        </div>
        <vs-row
          vs-type="flex"
          class="mb-2 mt-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <div class="w-full">
              <vs-input
                color="dark"
                class="mx-2"
                type="date"
                :disabled="true"
                style="width: 270px"
                name="emissao"
                @change="checkData"
                label="Data Cadastro"
                v-model="conta.data_emissao"
              ></vs-input>
            </div>
            <div class="w-full">
              <vs-input
                class="mx-2"
                name="vencimento"
                color="dark"
                type="date"
                style="width: 270px"
                label="Data Vencimento"
                v-model="conta.data_vencimento"
              ></vs-input>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-1 w-fulll"
              style="width: 600px"
              type="text"
              label="Observação"
              v-model="conta.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-button
              @click="submitForm()"
              class="w-full mt-3"
              color="success"
              type="relief"
              >Incluir</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  props: {},
  data() {
    return {
      modalCadastrar: false,
      loading: false,
      portadores: {},
      empresas: {},
      fornecedores: {},
      plano_conta: {},
      formas_pagamento: {},
      conta: { id_status: 1, parcela: 1, tipo: 1 }
    };
  },
  methods: {
    async openModal() {
      this.modalCadastrar = await true;
      await this.$vs.loading({ container: "#loadingSelectsCadCP", scale: 0.6 });
      try {
        this.conta.data_emissao = this.$formartData.dataFiltro();
        await this.getPortadores();
        await this.getEmpresas();
        await this.getFormasPagamento();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#loadingSelectsCadCP > .con-vs-loading");
      }
    },
    async submitForm() {
      try {
        if (
          this.conta.id_empresa != null &&
          this.conta.nota != null &&
          this.conta.valor != null &&
          this.conta.id_fornecedor != null &&
          this.conta.id_portador != null &&
          this.conta.id_plano_conta != null &&
          this.conta.id_forma_pagamento != null &&
          this.conta.data_emissao != null && this.conta.data_emissao != "" && this.conta.data_emissao != undefined &&
          this.conta.data_vencimento != null && this.conta.data_vencimento != "" && this.conta.data_vencimento != undefined
        ) {
          await this.Cadastrar();
        } else {
          this.$vs.notify({
            title: "Preencha os campos obrigatorios",
            text:
              "Empresa <br> Nota <br> Valor <br> Fornecedor <br> Portador <br> Plano Conta <br> Forma de Pagamento <br> Data de Emissão <br> Data de Vencimento",
            color: "danger"
          });
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async Cadastrar() {
      await this.$vs.loading({ container: "#loadingCadCP", scale: 0.6 });
      try {
        let valor = "" + this.conta.valor;
        if (valor && valor.search(",") !== -1) {
          valor = valor.replace(/\./g, "");
          valor = valor.replace(/,/g, ".");
        }
        this.conta.valor = valor;

        this.conta.id_status = await 1;
        this.conta.tipo = await 1;

        const res = await this.$http.post("conta", this.conta);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro da Conta a Pagar N°" + res.id
        };
        await this.$logger(logData);
        this.$emit("update");
        this.modalCadastrar = false;
        this.conta = {};
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.modalCadastrar = await false;
        this.open = await false;
        this.conta = await {};
        await this.$vs.loading.close("#loadingCadCP > .con-vs-loading");
      }
    },

    async getPortadores() {
      try {
        this.portadores = await this.$http.get(`portador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get(`empresa`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroFornecedor(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getFornecedorByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getFornecedorByQuery(query) {
      try {
        this.fornecedores = await this.$http.post(`getFornecedorByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPlanoContaByQuery(query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getFormasPagamento() {
      try {
        this.formas_pagamento = await this.$http.get(`formasPagamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroPlanoConta(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getPlanoContaByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async checkData() {
      var hoje = new Date();
      var data = new Date(this.conta.data_emissao);

      var dia_hoje = hoje.getUTCDate();
      var mes_hoje = hoje.getUTCMonth() + 1;
      var dia_data = data.getUTCDate();
      var mes_data = data.getUTCMonth() + 1;
      var ano_hoje = hoje.getUTCFullYear();
      var ano_data = data.getUTCFullYear();

      console.log("dia_hoje - " + dia_hoje);
      console.log("mes_hoje - " + mes_hoje);
      console.log("dia_data - " + dia_data);
      console.log("mes_data - " + mes_data);
      console.log("ano_hoje - " + ano_hoje);
      console.log("ano_data - " + ano_data);
      if (ano_data < ano_hoje && 0) {
        this.conta.data_emissao = hoje.toISOString().split("T")[0];
        this.$vs.notify({
          title:
            "Não é possível mudar o vencimento do ano anterior. ERR COD 03",
          text: "Ação Negada",
          color: "danger"
        });
        return 1;
      }
      if (dia_hoje > 5) {
        if (mes_data < mes_hoje) {
          this.conta.data_emissao = hoje.toISOString().split("T")[0];
          this.$vs.notify({
            title:
              "Não é possível mudar o vencimento do mês anterior. ERR COD 01",
            text: "Ação Negada",
            color: "danger"
          });
          return 1;
        }
      } else {
        if (mes_hoje + 1 < mes_data && !(mes_hoje == 1 && mes_data == 12)) {
          this.conta.data_emissao = hoje.toISOString().split("T")[0];
          this.$vs.notify({
            title:
              "Não é possível mudar o vencimento do mês anterior. ERR COD 02",
            text: "Ação Negada",
            color: "danger"
          });
          return 1;
        }
      }
    }
  },
  async mounted() {},
  components: { ...components }
};
</script>
<style lang="scss">
.cadastro-conta {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;
    .img {
      color: white !important;
    }
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
